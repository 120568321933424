import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-mage-armor-to-owls-wisdom"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Mage Armor - Owl's Wisdom</span>
    </h2>
    <h6><a id="mage-armor">Mage Armor</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>An <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> but tangible field of
force surrounds the subject
of a mage armor spell, providing a +4 armor bonus to AC.</p>
    <p>Unlike mundane armor, mage armor entails no armor check
penalty, arcane spell failure chance, or speed reduction. Since mage
armor is made of force, <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures
can&rsquo;t
bypass it the way
they do normal armor.</p>
    <p><i>Focus</i>: A piece of cured leather.</p>
    <h6><a id="mage-hand">Mage Hand</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One nonmagical,
unattended object weighing up to 5 lb.</span> <span className="stat-block"><b>Duration</b>:
Concentration</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You point your finger at an object and can lift it and move it
at will from a distance. As a move action, you can propel the object as
far as 15 feet in any direction, though the spell ends if the distance
between you and the object ever exceeds the spell&rsquo;s range.</p>
    <h6><a id="mages-disjunction">Mage&rsquo;s Disjunction</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Magic 9, Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: All magical
effects and
magic items within a 40-ft.-radius burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>All magical effects and magic items within the radius of the
spell, except for those that you carry or touch, are disjoined. That
is, spells and spell-like effects are separated into their individual
components (ending the effect as a dispel magic spell does), and each
permanent magic item must make a successful Will save or be turned into
a normal item. An item in a creature&rsquo;s possession uses its own
Will
save bonus or its possessor&rsquo;s Will save bonus, whichever is
higher.</p>
    <p>You also have a 1% chance per caster level of destroying an
antimagic field. If the antimagic field survives the disjunction, no
items within it are disjoined.</p>
    <p>Even artifacts are subject to disjunction, though there is
only a 1% chance per caster level of actually affecting such powerful
items. Additionally, if an artifact is destroyed, you must make a DC 25
Will save or permanently lose all spellcasting abilities. (These
abilities cannot be recovered by mortal magic, not even miracle or
wish.)</p>
    <p><i>Note</i>: Destroying artifacts is a dangerous business, and
it is 95% likely to attract the attention of some powerful being who
has an interest in or connection with the device.</p>
    <h6><a id="mages-faithful-hound">Mage&rsquo;s Faithful Hound</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Phantom watchdog</span>
    <span className="stat-block"><b>Duration</b>: 1 hour/caster level or
until discharged, then 1 round/caster level; see text</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You conjure up a phantom watchdog that is <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> to
everyone but yourself. It then guards the area where it was conjured
(it does not move). The hound immediately starts barking loudly if any
Small or larger creature approaches within 30 feet of it. (Those within
30 feet of the hound when it is conjured may move about in the area,
but if they leave and return, they activate the barking.) The hound
sees invisible and ethereal creatures. It does not react to figments,
but it does react to shadow illusions.</p>
    <p>If an intruder approaches to within 5 feet of the hound, the
dog stops barking and delivers a vicious bite (+10 attack bonus, 2d6+3
points of piercing damage) once per round. The dog also gets the
bonuses appropriate to an invisible creature.</p>
    <p>The dog is considered ready to bite intruders, so it delivers
its first bite on the intruder&rsquo;s turn. Its bite is the equivalent
of a
magic weapon for the purpose of <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a>. The hound cannot be
attacked, but it can be dispelled.</p>
    <p>The spell lasts for 1 hour per caster level, but once the
hound begins barking, it lasts only 1 round per caster level. If you
are ever more than 100 feet distant from the hound, the spell ends.</p>
    <p><i>Material Component</i>: A tiny silver whistle, a piece of
bone, and a thread.</p>
    <h6><a id="mages-lucubration">Mage&rsquo;s Lucubration</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span>
    <p>You instantly recall any one spell of 5th level or lower that
you have used during the past 24 hours. The spell must have been
actually cast during that period. The recalled spell is stored in your
mind as through prepared in the normal fashion.</p>
    <p>If the recalled spell requires material components, you must
provide them. The recovered spell is not usable until the material
components are available.</p>
    <h6><a id="mages-magnificent-mansion">Mage&rsquo;s Magnificent
Mansion</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>:
Extradimensional mansion,
up to three 10-ft. cubes/level (S)</span> <span className="stat-block"><b>Duration</b>:
2 hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You conjure up an extradimensional dwelling that has a single
entrance on the plane from which the spell was cast. The entry point
looks like a faint shimmering in the air that is 4 feet wide and 8 feet
high. Only those you designate may enter the mansion, and the portal is
shut and made <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> behind you when you
enter. You may open it
again from your own side at will. Once observers have passed beyond the
entrance, they are in a magnificent foyer with numerous chambers
beyond. The atmosphere is clean, fresh, and warm.</p>
    <p>You can create any floor plan you desire to the limit of the
spell&rsquo;s effect. The place is furnished and contains sufficient
foodstuffs to serve a nine-course banquet to a dozen people per caster
level. A staff of near-transparent servants (as many as two per caster
level), liveried and obedient, wait upon all who enter. The servants
function as unseen servant spells except that they are visible and can
go anywhere in the mansion.</p>
    <p>Since the place can be entered only through its special
portal, outside conditions do not affect the mansion, nor do conditions
inside it pass to the plane beyond.</p>
    <p><i>Focus</i>: A miniature portal carved from ivory, a small
piece of polished marble, and a tiny silver spoon (each item worth 5
gp).</p>
    <h6><a id="mages-private-sanctum">Mage&rsquo;s Private Sanctum</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: 30-ft. cube/level
(S)</span> <span className="stat-block"><b>Duration</b>: 24 hours (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell ensures privacy. Anyone looking into the area from
outside sees only a dark, foggy mass. Darkvision cannot penetrate it.
No sounds, no matter how loud, can escape the area, so nobody can
eavesdrop from outside. Those inside can see out normally.</p>
    <p>Divination (scrying) spells cannot perceive anything within
the area, and those within are immune to detect thoughts. The ward
prevents speech between those inside and those outside (because it
blocks sound), but it does not prevent other communication, such as a
sending or message spell, or telepathic communication, such as that
between a wizard and her familiar.</p>
    <p>The spell does not prevent creatures or objects from moving
into and out of the area.</p>
    <p>Mage&rsquo;s private sanctum can be made permanent with a
permanency
spell.</p>
    <p><i>Material Component</i>: A thin sheet of lead, a piece of
opaque glass, a wad of cotton or cloth, and powdered chrysolite.</p>
    <h6><a id="mages-sword">Mage&rsquo;s Sword</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: One sword</span>
    <span className="stat-block"><b>Duration</b>: 1 round/level (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell brings into being a shimmering, swordlike plane of
force. The sword strikes at any opponent within its range, as you
desire, starting in the round that you cast the spell. The sword
attacks its designated target once each round on your turn. Its attack
bonus is equal to your caster level + your Int bonus or your Cha bonus
(for wizards or sorcerers, respectively) with an additional +3
enhancement bonus. As a force effect, it can strike ethereal and <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures. It deals
4d6+3 points of force damage, with a
threat range of 19&ndash;20 and a critical multiplier of x2.</p>
    <p>The sword always strikes from your direction. It does not get
a bonus for flanking or help a combatant get one. If the sword goes
beyond the spell range from you, if it goes out of your sight, or if
you are not directing it, the sword returns to you and hovers.</p>
    <p>Each round after the first, you can use a standard action to
switch the sword to a new target. If you do not, the sword continues to
attack the previous round&rsquo;s target.</p>
    <p>The sword cannot be attacked or harmed by physical attacks,
but dispel magic, disintegrate, a sphere of annihilation, or a rod of
cancellation affects it. The sword&rsquo;s AC is 13 (10, +0 size bonus
for
Medium object, +3 deflection bonus).</p>
    <p>If an attacked creature has spell resistance, the resistance
is checked the first time Mage&rsquo;s sword strikes it. If the sword
is
successfully resisted, the spell is dispelled. If not, the sword has
its normal full effect on that creature for the duration of the spell.</p>
    <p><i>Focus</i>: A miniature platinum sword with a grip and
pommel of copper and zinc. It costs 250 gp to construct.</p>
    <h6><a id="magic-aura">Magic Aura</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Brd 1, Magic 1,
Sor/Wiz 1
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, F
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span>
One touched object
weighing up&nbsp; to 5 lb./level 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> One day/level
(D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None; see
text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>You alter an item&rsquo;s aura so that it registers to detect
spells
(and spells with similar capabilities) as though it were nonmagical, or
a magic item of a kind you specify, or the subject of a spell you
specify. </p>
    <p>If the object bearing magic aura has identify cast on it or is
similarly examined, the examiner recognizes that the aura is false and
detects the object&rsquo;s actual qualities if he succeeds on a Will
save.
Otherwise, he believes the aura and no amount of testing reveals what
the true magic is.</p>
    <p>If the targeted item&rsquo;s own aura is exceptionally
powerful (if
it is an artifact, for instance), magic aura doesn&rsquo;t work.</p>
    <p><i>Note</i>: A magic weapon, shield, or suit of armor must be
a masterwork item, so a sword of average make, for example, looks
suspicious if it has a magical aura.</p>
    <p><i>Focus</i>: A small square of silk that must be passed over
the object that receives the aura.</p>
    <h6><a id="magic-circle-against-chaos">Magic Circle against
Chaos</a></h6>
    <p className="initial"><i>Abjuration [Lawful]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Law 3, Pal 3,
Sor/Wiz 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#magic-circle-against-evil">magic circle against evil</a>, except
that it is similar to protection from chaos instead of protection from
evil, and it can imprison a nonlawful called creature.</p>
    <h6><a id="magic-circle-against-evil">Magic Circle against Evil</a></h6>
    <p className="initial"><i>Abjuration [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Good 3, Pal 3,
Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius emanation
from touched creature</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
No; see text</span>
    <p>All creatures within the area gain the effects of a protection
from evil spell, and no nongood summoned creatures can enter the area
either. You must overcome a creature&rsquo;s spell resistance in order
to
keep it at bay (as in the third function of protection from evil), but
the deflection and resistance bonuses and the protection from mental
control apply regardless of enemies&rsquo; spell resistance.</p>
    <p>This spell has an alternative version that you may choose when
casting it. A magic circle against evil can be focused inward rather
than outward. When focused inward, the spell binds a nongood called
creature (such as those called by the lesser planar binding, planar
binding, and greater planar binding spells) for a maximum of 24 hours
per caster level, provided that you cast the spell that calls the
creature within 1 round of casting the magic circle. The creature
cannot cross the circle&rsquo;s boundaries. If a creature too large to
fit
into the spell&rsquo;s area is the subject of the spell, the spell acts
as a
normal protection from evil spell for that creature only.</p>
    <p>A magic circle leaves much to be desired as a trap. If the
circle of powdered silver laid down in the process of spellcasting is
broken, the effect immediately ends. The trapped creature can do
nothing that disturbs the circle, directly or indirectly, but other
creatures can. If the called creature has spell resistance, it can test
the trap once a day. If you fail to overcome its spell resistance, the
creature breaks free, destroying the circle. A creature capable of any
form of dimensional travel (astral projection, blink, dimension door,
etherealness, gate, plane shift, shadow walk, teleport, and similar
abilities) can simply leave the circle through that means. You can
prevent the creature&rsquo;s extradimensional escape by casting a
dimensional
anchor spell on it, but you must cast the spell before the creature
acts. If you are successful, the anchor effect lasts as long as the
magic circle does. The creature cannot reach across the magic circle,
but its ranged attacks (ranged weapons, spells, magical abilities, and
the like) can. The creature can attack any target it can reach with its
ranged attacks except for the circle itself.</p>
    <p>You can add a special diagram (a two-dimensional bounded
figure with no gaps along its circumference, augmented with various
magical sigils) to make the magic circle more secure. Drawing the
diagram by hand takes 10 minutes and requires a DC 20 <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
check.
You do not know the result of this check. If the check fails, the
diagram is ineffective. You can take 10 when drawing the diagram if you
are under no particular time pressure to complete the task. This task
also takes 10 full minutes. If time is no factor at all, and you devote
3 hours and 20 minutes to the task, you can take 20.</p>
    <p>A successful diagram allows you to cast a dimensional anchor
spell on the magic circle during the round before casting any summoning
spell. The anchor holds any called creatures in the magic circle for 24
hours per caster level. A creature cannot use its spell resistance
against a magic circle prepared with a diagram, and none of its
abilities or attacks can cross the diagram. If the creature tries a
Charisma check to break free of the trap (see the lesser planar binding
spell), the DC increases by 5. The creature is immediately released if
anything disturbs the diagram&mdash;even a straw laid across it.
However, the
creature itself cannot disturb the diagram either directly or
indirectly, as noted above.</p>
    <p>This spell is not cumulative with protection from evil and
vice versa.</p>
    <p><i>Arcane Material Component</i>: A little powdered silver
with which you trace a 3-footdiameter circle on the floor (or ground)
around the creature to be warded.</p>
    <h6><a id="magic-circle-against-good">Magic Circle against Good</a></h6>
    <p className="initial"><i>Abjuration [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Evil 3, Sor/Wiz 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#magic-circle-against-evil">magic circle against evil</a>, except
that it is similar to protection from good instead of protection from
evil, and it can imprison a nonevil called creature.</p>
    <h6><a id="magic-circle-against-law">Magic Circle against Law</a></h6>
    <p className="initial"><i>Abjuration [Chaotic]</i></p>
    <span className="stat-block"><b>Level</b>: Chaos 3, Clr 3, Sor/Wiz 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#magic-circle-against-evil">magic circle against evil</a>, except
that it is similar to protection from law instead of protection from
evil, and it can imprison a nonchaotic called creature.</p>
    <h6><a id="magic-fang">Magic Fang</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>Magic fang gives one natural weapon of the subject a +1
enhancement bonus on attack and damage rolls. The spell can affect a
slam attack, fist, bite, or other natural weapon. (The spell does not
change an unarmed strike&rsquo;s damage from nonlethal damage to lethal
damage.)</p>
    <p>Magic fang can be made permanent with a permanency spell.</p>
    <h6><a id="greater-magic-fang">Magic Fang, Greater</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 3, Rgr 3</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: 1 hour/level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#magic-fang">magic fang</a>, except that the
enhancement bonus on attack and damage rolls is +1 per four caster
levels (maximum +5).</p>
    <p>Alternatively, you may imbue all of the creature&rsquo;s
natural
weapons with a +1 enhancement bonus (regardless of your caster level).</p>
    <p>Greater magic fang can be made permanent with a permanency
spell.</p>
    <h6><a id="magic-jar">Magic Jar</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: 1 hour/level or until
you return to your body</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>By casting magic jar, you place your soul in a gem or large
crystal (known as the magic jar), leaving your body lifeless. Then you
can attempt to take control of a nearby body, forcing its soul into the
magic jar. You may move back to the jar (thereby returning the trapped
soul to its body) and attempt to possess another body. The spell ends
when you send your soul back to your own body, leaving the receptacle
empty.</p>
    <p>To cast the spell, the magic jar must be within spell range
and you must know where it is, though you do not need line of sight or
line of effect to it. When you transfer your soul upon casting, your
body is, as near as anyone can tell, dead. </p>
    <p>While in the magic jar, you can sense and attack any life
force within 10 feet per caster level (and on the same plane of
existence). You do need line of effect from the jar to the creatures.
You cannot determine the exact creature types or positions of these
creatures. In a group of life forces, you can sense a difference of 4
or more Hit Dice between one creature and another and can determine
whether a life force is powered by positive or negative energy. (Undead
creatures are powered by negative energy. Only sentient undead
creatures have, or are, souls.)</p>
    <p>You could choose to take over either a stronger or a weaker
creature, but which particular stronger or weaker creature you attempt
to possess is determined randomly.</p>
    <p>Attempting to possess a body is a full-round action. It is
blocked by protection from evil or a similar ward. You possess the body
and force the creature&rsquo;s soul into the magic jar unless the
subject
succeeds on a Will save. Failure to take over the host leaves your life
force in the magic jar, and the target automatically succeeds on
further saving throws if you attempt to possess its body again.</p>
    <p>If you are successful, your life force occupies the host body,
and the host&rsquo;s life force is imprisoned in the magic jar. You
keep your
Intelligence, Wisdom, Charisma, level, class, base attack bonus, base
save bonuses, alignment, and mental abilities. The body retains its
Strength, Dexterity, Constitution, hit points, natural abilities, and
automatic abilities. A body with extra limbs does not allow you to make
more attacks (or more advantageous two-weapon attacks) than normal. You
can&rsquo;t choose to activate the body&rsquo;s extraordinary or
supernatural
abilities. The creature&rsquo;s spells and spell-like abilities do not
stay
with the body.</p>
    <p>As a standard action, you can shift freely from a host to the
magic jar if within range, sending the trapped soul back to its body.
The spell ends when you shift from the jar to your own body.</p>
    <p>If the host body is slain, you return to the magic jar, if
within range, and the life force of the host departs (it is <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>). If
the host body is slain beyond the range of the spell, both you and the
host die. Any life force with nowhere to go is treated as slain.</p>
    <p>If the spell ends while you are in the magic jar, you return
to your body (or die if your body is out of range or destroyed). If the
spell ends while you are in a host, you return to your body (or die, if
it is out of range of your current position), and the soul in the magic
jar returns to its body (or dies if it is out of range). Destroying the
receptacle ends the spell, and the spell can be dispelled at either the
magic jar or at the host&rsquo;s location.</p>
    <p><i>Focus</i>: A gem or crystal worth at least 100 gp.</p>
    <h6><a id="magic-missile">Magic Missile</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: Up to five
creatures, no
two of which can be more than 15 ft. apart</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A missile of magical energy darts forth from your fingertip
and strikes its target, dealing 1d4+1 points of force damage.</p>
    <p>The missile strikes unerringly, even if the target is in melee
combat or has less than total cover or total concealment. Specific
parts of a creature can&rsquo;t be singled out. Inanimate objects are
not
damaged by the spell.</p>
    <p>For every two caster levels beyond 1st, you gain an additional
missile&mdash;two at 3rd level, three at 5th, four at 7th, and the
maximum of
five missiles at 9th level or higher. If you shoot multiple missiles,
you can have them strike a single creature or several creatures. A
single missile can strike only one creature. You must designate targets
before you check for spell resistance or roll damage.</p>
    <h6><a id="magic-mouth">Magic Mouth</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature or
object</span> <span className="stat-block"><b>Duration</b>: Permanent
until
discharged</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This spell imbues the chosen object or creature with an
enchanted mouth that suddenly appears and speaks its message the next
time a specified event occurs. The message, which must be twenty-five
or fewer words long, can be in any language known by you and can be
delivered over a period of 10 minutes. The mouth cannot utter verbal
components, use command words, or activate magical effects. It does,
however, move according to the words articulated; if it were placed
upon a statue, the mouth of the statue would move and appear to speak.
Of course, magic mouth can be placed upon a tree, rock, or any other
object or creature.</p>
    <p>The spell functions when specific conditions are fulfilled
according to your command as set in the spell. Commands can be as
general or as detailed as desired, although only visual and audible
triggers can be used. Triggers react to what appears to be the case.
Disguises and illusions can fool them. Normal darkness does not defeat
a visual trigger, but magical darkness or invisibility does. Silent
movement or magical silence defeats audible triggers. Audible triggers
can be keyed to general types of noises or to a specific noise or
spoken word. Actions can serve as triggers if they are visible or
audible. A magic mouth cannot distinguish alignment, level, Hit Dice,
or class except by external garb.</p>
    <p>The range limit of a trigger is 15 feet per caster level, so a
6th-level caster can command a magic mouth to respond to triggers as
far as 90 feet away. Regardless of range, the mouth can respond only to
visible or audible triggers and actions in line of sight or within
hearing distance.</p>
    <p>Magic mouth can be made permanent with a permanency spell.</p>
    <p><i>Material Component</i>: A small bit of honeycomb and jade
dust worth 10 gp.</p>
    <h6><a id="magic-stone">Magic Stone</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Drd 1, Earth 1</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
Up to three pebbles
touched</span> <span className="stat-block"><b>Duration</b>: 30 minutes or
until
discharged</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>You transmute as many as three pebbles, which can be no larger
than sling bullets, so that they strike with great force when thrown or
slung. If hurled, they have a range increment of 20 feet. If slung,
treat them as sling bullets (range increment 50 feet). The spell gives
them a +1 enhancement bonus on attack and damage rolls. The user of the
stones makes a normal ranged attack. Each stone that hits deals 1d6+1
points of damage (including the spell&rsquo;s enhancement bonus), or
2d6+2
points against undead.</p>
    <h6><a id="magic-vestment">Magic Vestment</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Strength 3, War 3</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Armor or shield touched</span> <span className="stat-block"><b>Duration</b>:
1 hour/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>You imbue a suit of armor or a shield with an enhancement
bonus of +1 per four caster levels (maximum +5 at 20th level).</p>
    <p>An outfit of regular clothing counts as armor that grants no
AC bonus for the purpose of this spell.</p>
    <h6><a id="magic-weapon">Magic Weapon</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Pal 1, Sor/Wiz 1,
War 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Weapon touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>Magic weapon gives a weapon a +1 enhancement bonus on attack
and damage rolls. (An enhancement bonus does not stack with a
masterwork weapon&rsquo;s +1 bonus on attack rolls.)</p>
    <p>You can&rsquo;t cast this spell on a natural weapon, such as
an
unarmed strike (instead, see magic fang). A monk&rsquo;s unarmed strike
is
considered a weapon, and thus it can be enhanced by this spell.</p>
    <h6><a id="greater-magic-weapon">Magic Weapon, Greater</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Pal 3, Sor/Wiz 3</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One weapon or
fifty
projectiles (all of which must be in contact with each other at the
time of casting)</span> <span className="stat-block"><b>Duration</b>: 1
hour/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#magic-weapon">magic weapon</a>, except that it gives a
weapon an enhancement bonus on attack and damage rolls of +1 per four
caster levels (maximum +5).</p>
    <p>Alternatively, you can affect as many as fifty arrows, bolts,
or bullets. The projectiles must be of the same kind, and they have to
be together (in the same quiver or other container). Projectiles, but
not thrown weapons, lose their transmutation when used. (Treat shuriken
as projectiles, rather than as thrown weapons, for the purpose of this
spell.)</p>
    <p><i>Arcane Material Component</i>: Powdered lime and carbon.</p>
    <h6><a id="major-creation">Major Creation</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Duration</b>: See text</span>
    <p><i>This spell functions like <a style={{
          "color": "#579eb6"
        }} href="#minor-creation">minor creation</a>, except that you
can also create an object of mineral nature</i>: stone, crystal, metal,
or the like. The duration of the created item varies with its relative
hardness and rarity, as indicated on the following table.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>Hardness and Rarity Examples</th>
          <th>Duration</th>
        </tr>
        <tr className="odd-row">
          <td>Vegetable matter</td>
          <td>2 hr./level</td>
        </tr>
        <tr>
          <td>Stone, crystal, base metals</td>
          <td>1 hr./level</td>
        </tr>
        <tr className="odd-row">
          <td>Precious metals</td>
          <td>20 min./level</td>
        </tr>
        <tr>
          <td>Gems</td>
          <td>10 min./level</td>
        </tr>
        <tr className="odd-row">
          <td>Rare metal<sup>1</sup></td>
          <td>1 round/level</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>Includes adamantine, alchemical
silver, and mithral. You can&rsquo;t use major creation to create a
cold iron
item.</i></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="major-image">Major Image</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Duration</b>: Concentration + 3 rounds</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#silent-image">silent image</a>, except that sound,
smell, and thermal illusions are included in the spell effect. While
concentrating, you can move the image within the range. </p>
    <p>The image disappears when struck by an opponent unless you
cause the illusion to react appropriately.</p>
    <h6><a id="make-whole">Make Whole</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One object of
up to 10
cu. ft./ level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mending">mending</a>, except that make whole
completely repairs an object made of any substance, even one with
multiple breaks, to be as strong as new. The spell does not restore the
magical abilities of a broken magic item made whole, and it cannot mend
broken magic rods, staffs, or wands. The spell does not repair items
that have been warped, burned, disintegrated, ground to powder, melted,
or vaporized, nor does it affect creatures (including constructs).</p>
    <h6><a id="mark-of-justice">Mark of Justice</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Pal 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Permanent; see text</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You draw an indelible mark on the subject and state some
behavior on the part of the subject that will activate the mark. When
activated, the mark curses the subject. Typically, you designate some
sort of criminal behavior that activates the mark, but you can pick any
act you please. The effect of the mark is identical with the effect of
bestow curse.</p>
    <p>Since this spell takes 10 minutes to cast and involves writing
on the target, you can cast it only on a creature that is willing or
restrained.</p>
    <p>Like the effect of bestow curse, a mark of justice cannot be
dispelled, but it can be removed with a break enchantment, limited
wish, miracle, remove curse, or wish spell. Remove curse works only if
its caster level is equal to or higher than your mark of justice caster
level. These restrictions apply regardless of whether the mark has
activated.</p>
    <h6><a id="mass-spell-name">Mass (Spell Name)</a></h6>
    <p className="initial"><i>Any spell whose name begins with mass is
alphabetized in this chapter according to the second word of the spell
name. Thus, the description of a mass spell appears near the
description of the spell on which it is based. Spell chains that have
mass spells in them include those based on the spells bear&rsquo;s
endurance,
bull&rsquo;s strength, cat&rsquo;s grace, charm monster, cure critical
wounds, cure
light wounds, cure moderate wounds, cure serious wounds, eagle&rsquo;s
splendor, enlarge person, fox&rsquo;s cunning, heal, hold monster, hold
person, inflict critical wounds, inflict light wounds, inflict moderate
wounds, inflict serious wounds, invisibility, owl&rsquo;s wisdom,
reduce
person, and suggestion.</i></p>
    <h6><a id="maze">Maze</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: See text</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You banish the subject into an extradimensional labyrinth of
force planes. Each round on its turn, it may attempt a DC 20
Intelligence check to escape the labyrinth as a full-round action. If
the subject doesn&rsquo;t escape, the maze disappears after 10 minutes,
forcing the subject to leave.</p>
    <p>On escaping or leaving the maze, the subject reappears where
it had been when the maze spell was cast. If this location is filled
with a solid object, the subject appears in the nearest open space.
Spells and abilities that move a creature within a plane, such as
teleport and dimension door, do not help a creature escape a maze
spell, although a plane shift spell allows it to exit to whatever plane
is designated in that spell. Minotaurs are not affected by this spell.</p>
    <h6><a id="meld-into-stone">Meld into Stone</a></h6>
    <p className="initial"><i>Transmutation [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Drd 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span>
    <p>Meld into stone enables you to meld your body and possessions
into a single block of stone. The stone must be large enough to
accommodate your body in all three dimensions. When the casting is
complete, you and not more than 100 pounds of nonliving gear merge with
the stone. If either condition is violated, the spell fails and is
wasted.</p>
    <p>While in the stone, you remain in contact, however tenuous,
with the face of the stone through which you melded. You remain aware
of the passage of time and can cast spells on yourself while hiding in
the stone. Nothing that goes on outside the stone can be seen, but you
can still hear what happens around you. Minor physical damage to the
stone does not harm you, but its partial destruction (to the extent
that you no longer fit within it) expels you and deals you 5d6 points
of damage. The stone&rsquo;s complete destruction expels you and slays
you
instantly unless you make a DC 18 Fortitude save.</p>
    <p>Any time before the duration expires, you can step out of the
stone through the surface that you entered. If the spell&rsquo;s
duration
expires or the effect is dispelled before you voluntarily exit the
stone, you are violently expelled and take 5d6 points of damage.</p>
    <p><i>The following spells harm you if cast upon the stone that
you are occupying</i>: Stone to flesh expels you and deals you 5d6
points of damage. Stone shape deals you 3d6 points of damage but does
not expel you. Transmute rock to mud expels you and then slays you
instantly unless you make a DC 18 Fortitude save, in which case you are
merely expelled. Finally, passwall expels you without damage.</p>
    <h6><a id="mending">Mending</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Clr 0, Drd 0,
Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: 10 ft.</span> <span className="stat-block"><b>Target</b>: One object of up to 1 lb.</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>Mending repairs small breaks or tears in objects (but not
warps, such as might be caused by a warp wood spell). It will weld
broken metallic objects such as a ring, a chain link, a medallion, or a
slender dagger, providing but one break exists.</p>
    <p>Ceramic or wooden objects with multiple breaks can be
invisibly rejoined to be as strong as new. A hole in a leather sack or
a wineskin is completely healed over by mending. The spell can repair a
magic item, but the item&rsquo;s magical abilities are not restored.
The
spell cannot mend broken magic rods, staffs, or wands, nor does it
affect creatures (including constructs).</p>
    <h6><a id="message">Message</a></h6>
    <p className="initial"><i>Transmutation [Language-Dependent]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: One
creature/level</span> <span className="stat-block"><b>Duration</b>: 10
min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can whisper messages and receive whispered replies with
little chance of being overheard. You point your finger at each
creature you want to receive the message. When you whisper, the
whispered message is audible to all targeted creatures within range.
Magical silence, 1 foot of stone, 1 inch of common metal (or a thin
sheet of lead), or 3 feet of wood or dirt blocks the spell. The message
does not have to travel in a straight line. It can circumvent a barrier
if there is an open path between you and the subject, and the
path&rsquo;s
entire length lies within the spell&rsquo;s range. The creatures that
receive
the message can whisper a reply that you hear. The spell transmits
sound, not meaning. It doesn&rsquo;t transcend language barriers.</p>
    <p><i>Note</i>: To speak a message, you must mouth the words and
whisper, possibly allowing observers the opportunity to read your lips.</p>
    <p><i>Focus</i>: A short piece of copper wire.</p>
    <h6><a id="meteor-swarm">Meteor Swarm</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Four
40-ft.-radius spreads;
see text</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: None or Reflex
half; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Meteor swarm is a very powerful and spectacular spell that is
similar to fireball in many aspects. When you cast it, four 2-
foot-diameter spheres spring from your outstretched hand and streak in
straight lines to the spots you select. The meteor spheres leave a
fiery trail of sparks.</p>
    <p>If you aim a sphere at a specific creature, you may make a
ranged touch attack to strike the target with the meteor. Any creature
struck by one of these spheres takes 2d6 points of bludgeoning damage
(no save) and receives no saving throw against the sphere&rsquo;s fire
damage
(see below). If a targeted sphere misses its target, it simply explodes
at the nearest corner of the target&rsquo;s space. You may aim more
than one
meteor at the same target.</p>
    <p>Once a sphere reaches its destination, it explodes in a
40-foot-radius spread, dealing 6d6 points of fire damage to each
creature in the area. If a creature is within the area of more than one
sphere, it must save separately against each. (Fire resistance applies
to each sphere&rsquo;s damage individually.)</p>
    <h6><a id="mind-blank">Mind Blank</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Protection 8, Sor/Wiz 8</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: 24 hours</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject is protected from all devices and spells that
detect, influence, or read emotions or thoughts. This spell protects
against all mind-affecting spells and effects as well as information
gathering by divination spells or effects. Mind blank even foils
limited wish, miracle, and wish spells when they are used in such a way
as to affect the subject&rsquo;s mind or to gain information about it.
In the
case of scrying that scans an area the creature is in, such as arcane
eye, the spell works but the creature simply isn&rsquo;t detected.
Scrying
attempts that are targeted specifically at the subject do not work at
all.</p>
    <h6><a id="mind-fog">Mind Fog</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Fog spreads
in 20-ft.
radius, 20 ft. high</span> <span className="stat-block"><b>Duration</b>:
30 minutes and 2d6
rounds; see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Mind fog produces a bank of thin mist that weakens the mental
resistance of those caught in it. Creatures in the mind fog take a
&ndash;10
competence penalty on Wisdom checks and Will saves. (A creature that
successfully saves against the fog is not affected and need not make
further saves even if it remains in the fog.) Affected creatures take
the penalty as long as they remain in the fog and for 2d6 rounds
thereafter. The fog is stationary and lasts for 30 minutes (or until
dispersed by wind).</p>
    <p>A moderate wind (11+ mph) disperses the fog in four rounds; a
strong wind (21+ mph) disperses the fog in 1 round.</p>
    <p>The fog is thin and does not significantly hamper vision.</p>
    <h6><a id="minor-creation">Minor Creation</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
Unattended, nonmagical
object of nonliving plant matter, up to 1 cu. ft./level</span> <span className="stat-block"><b>Duration</b>: 1 hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You create a nonmagical, unattended object of nonliving,
vegetable matter. The volume of the item created cannot exceed 1 cubic
foot per caster level. You must succeed on an appropriate skill check
to make a complex item.</p>
    <p>Attempting to use any created object as a material component
causes the spell to fail.</p>
    <p><i>Material Component</i>: A tiny piece of matter of the same
sort of item you plan to create with minor creation.</p>
    <h6><a id="minor-image">Minor Image</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Duration</b>: Concentration +2 rounds</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#silent-image">silent image</a>, except that minor
image includes some minor sounds but not understandable speech.</p>
    <h6><a id="miracle">Miracle</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Luck 9</span> <span className="stat-block"><b>Components</b>: V, S, XP; see text</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: See text</span> <span style={{
      "fontWeight": "bold"
    }}>Target, Effect, or Area:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>You don&rsquo;t so much cast a miracle as request one. You
state
what you would like to have happen and request that your deity (or the
power you pray to for spells) intercede.</p>
    <p>A miracle can do any of the following things.</p>
    <ul>
      <li>
        <p>Duplicate any cleric spell of 8th level or lower
(including spells to which you have access because of your domains). </p>
      </li>
      <li>
        <p>Duplicate any other spell of 7th level or lower.</p>
      </li>
      <li>
        <p>Undo the harmful effects of certain spells, such as
feeblemind or insanity.</p>
      </li>
      <li>
        <p>Have any effect whose power level is in line with the
above effects.</p>
      </li>
    </ul>
    <p>If the miracle has any of the above effects, casting it has no
experience point cost.</p>
    <p>Alternatively, a cleric can make a very powerful request.
Casting such a miracle costs the cleric 5,000 XP because of the
powerful divine energies involved. Examples of especially powerful
miracles of this sort could include the following.</p>
    <ul>
      <li>
        <p>Swinging the tide of a battle in your favor by raising
fallen allies to continue fighting.</p>
      </li>
      <li>
        <p>Moving you and your allies, with all your and their gear,
from one plane to another through planar barriers to a specific locale
with no chance of error.</p>
      </li>
      <li>
        <p>Protecting a city from an earthquake, volcanic eruption,
flood, or other major natural disaster.</p>
      </li>
    </ul>
    <p>In any event, a request that is out of line with the
deity&rsquo;s
(or alignment&rsquo;s) nature is refused.</p>
    <p>A duplicated spell allows saving throws and spell resistance
as normal, but the save DCs are as for a 9th-level spell. When a
miracle duplicates a spell that has an XP cost, you must pay that cost.
When a miracle spell duplicates a spell with a material component that
costs more than 100 gp, you must provide that component.</p>
    <p><i>XP Cost</i>: 5,000 XP (for some uses of the miracle spell;
see above).</p>
    <h6><a id="mirage-arcana">Mirage Arcana</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Area</b>: One 20-ft. cube/level (S)</span> <span className="stat-block"><b>Duration</b>: Concentration +1 hour/
level (D)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#hallucinatory-terrain">hallucinatory terrain</a>, except that
it enables you to make any area appear to be something other than it
is. The illusion includes audible, visual, tactile, and olfactory
elements. Unlike hallucinatory terrain, the spell can alter the
appearance of structures (or add them where none are present). Still,
it can&rsquo;t disguise, conceal, or add creatures (though creatures
within
the area might hide themselves within the illusion just as they can
hide themselves within a real location).</p>
    <h6><a id="mirror-image">Mirror Image</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal; see text</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>Several illusory duplicates of you pop into being, making it
difficult for enemies to know which target to attack. The figments stay
near you and disappear when struck.</p>
    <p>Mirror image creates 1d4 images plus one image per three
caster levels (maximum eight images total). These figments separate
from you and remain in a cluster, each within 5 feet of at least one
other figment or you. You can move into and through a mirror image.
When you and the mirror image separate, observers can&rsquo;t use
vision or
hearing to tell which one is you and which the image. The figments may
also move through each other. The figments mimic your actions,
pretending to cast spells when you cast a spell, drink potions when you
drink a potion, levitate when you levitate, and so on.</p>
    <p>Enemies attempting to attack you or cast spells at you must
select from among indistinguishable targets. Generally, roll randomly
to see whether the selected target is real or a figment. Any successful
attack against an image destroys it. An image&rsquo;s AC is 10 + your
size
modifier + your Dex modifier. Figments seem to react normally to area
spells (such as looking like they&rsquo;re burned or dead after being
hit by
a fireball).</p>
    <p>While moving, you can merge with and split off from figments
so that enemies who have learned which image is real are again
confounded.</p>
    <p>An attacker must be able to see the images to be fooled. If
you are <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> or an attacker shuts
his or her eyes, the spell has
no effect. (Being unable to see carries the same penalties as being <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a>.)</p>
    <h6><a id="misdirection">Misdirection</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature or
object,
up to a 10-ft. cube in size</span> <span className="stat-block"><b>Duration</b>:
1 hour/level</span> <span className="stat-block"><b>Saving Throw</b>: None
or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>By means of this spell, you misdirect the information from
divination spells that reveal auras (detect evil, detect magic, discern
lies, and the like). On casting the spell, you choose another object
within range. For the duration of the spell, the subject of
misdirection is detected as if it were the other object. (Neither the
subject nor the other object gets a saving throw against this effect.)
Detection spells provide information based on the second object rather
than on the actual target of the detection unless the caster of the
detection succeeds on a Will save. For instance, you could make
yourself detect as a tree if one were within range at casting: not
evil, not lying, not magical, neutral in alignment, and so forth. This
spell does not affect other types of divination magic (augury, detect
thoughts, clairaudience/clairvoyance, and the like).</p>
    <h6><a id="mislead">Mislead</a></h6>
    <p className="initial"><i>Illusion (Figment, Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Luck 6, Sor/Wiz 6,
Trickery 6</span> <span className="stat-block"><b>Components</b>: S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span style={{
      "fontWeight": "bold"
    }}>Target/Effect:</span>
You/one illusory double 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D) and concentration + 3
rounds; see text 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None or
Will disbelief (if interacted
with); see text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>You become <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> (as improved
invisibility, a glamer), and
at the same time, an illusory double of you (as major image, a figment)
appears. You are then free to go elsewhere while your double moves
away. The double appears within range but thereafter moves as you
direct it (which requires concentration beginning on the first round
after the casting). You can make the figment appear superimposed
perfectly over your own body so that observers don&rsquo;t notice an
image
appearing and you turning invisible. You and the figment can then move
in different directions. The double moves at your speed and can talk
and gesture as if it were real, but it cannot attack or cast spells,
though it can pretend to do so.</p>
    <p>The illusory double lasts as long as you concentrate upon it,
plus 3 additional rounds. After you cease concentration, the illusory
double continues to carry out the same activity until the duration
expires. The improved invisibility lasts for 1 round per level,
regardless of concentration.</p>
    <h6><a id="mnemonic-enhancer">Mnemonic Enhancer</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span>
    <p>Casting this spell allows you to prepare additional spells or
retain spells recently cast. Pick one of these two versions when the
spell is cast.</p>
    <p><i>Prepare</i>: You prepare up to three additional levels of
spells. A cantrip counts as 1/2 level for this purpose. You prepare and
cast these spells normally.</p>
    <p><i>Retain</i>: You retain any spell of 3rd level or lower that
you had cast up to 1 round before you started casting the mnemonic
enhancer. This restores the previously cast spell to your mind.</p>
    <p>In either event, the spell or spells prepared or retained fade
after 24 hours (if not cast).</p>
    <p><i>Material Component</i>: A piece of string, and ink
consisting of squid secretion with black dragon&rsquo;s blood.</p>
    <p><i>Focus</i>: An ivory plaque of at least 50 gp value.</p>
    <h6><a id="modify-memory">Modify Memory</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round; see text</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: Permanent</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You reach into the subject&rsquo;s mind and modify as many as
5
minutes of its memories in one of the following ways.</p>
    <ul>
      <li>
        <p>Eliminate all memory of an event the subject actually
experienced. This spell cannot negate charm, geas/quest, suggestion, or
similar spells.</p>
      </li>
      <li>
        <p>Allow the subject to recall with perfect clarity an event
it actually experienced.</p>
      </li>
      <li>
        <p>Change the details of an event the subject actually
experienced.</p>
      </li>
      <li>
        <p>Implant a memory of an event the subject never experienced.</p>
      </li>
    </ul>
    <p>Casting the spell takes 1 round. If the subject fails to save,
you proceed with the spell by spending as much as 5 minutes (a period
of time equal to the amount of memory time you want to modify)
visualizing the memory you wish to modify in the subject. If your
concentration is disturbed before the visualization is complete, or if
the subject is ever beyond the spell&rsquo;s range during this time,
the
spell is lost.</p>
    <p>A modified memory does not necessarily affect the
subject&rsquo;s
actions, particularly if it contradicts the creature&rsquo;s natural
inclinations. An illogical modified memory is dismissed by the creature
as a bad dream or a memory muddied by too much wine. </p>
    <h6><a id="moment-of-prescience">Moment of Prescience</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Luck 8, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level or until
discharged</span>
    <p>This spell grants you a powerful sixth sense in relation to
yourself. Once during the spell&rsquo;s duration, you may choose to use
its
effect. This spell grants you an insight bonus equal to your caster
level (maximum +25) on any single attack roll, opposed ability or skill
check, or saving throw. Alternatively, you can apply the insight bonus
to your AC against a single attack (even if flatfooted). Activating the
effect doesn&rsquo;t take an action; you can even activate it on
another
character&rsquo;s turn if needed. You must choose to use the moment of
prescience before you make the roll it is to modify. Once used, the
spell ends.</p>
    <p>You can&rsquo;t have more than one moment of prescience active
on
you at the same time.</p>
    <h6><a id="mount">Mount</a></h6>
    <p className="initial"><i>Conjuration (Summoning)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: One mount</span>
    <span className="stat-block"><b>Duration</b>: 2 hours/level (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You summon a light horse or a pony (your choice) to serve you
as a mount. The steed serves willingly and well. The mount comes with a
bit and bridle and a riding saddle.</p>
    <p><i>Material Component</i>: A bit of horse hair.</p>
    <h6><a id="move-earth">Move Earth</a></h6>
    <p className="initial"><i>Transmutation [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: See text</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Dirt in an
area up to 750
ft. square and up to 10 ft. deep (S)</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Move earth moves dirt (clay, loam, sand), possibly collapsing
embankments, moving hillocks, shifting dunes, and so forth.</p>
    <p>However, in no event can rock formations be collapsed or
moved. The area to be affected determines the casting time. For every
150-foot square (up to 10 feet deep), casting takes 10 minutes. The
maximum area, 750 feet by 750 feet, takes 4 hours and 10 minutes to
move.</p>
    <p>This spell does not violently break the surface of the ground.
Instead, it creates wavelike crests and troughs, with the earth
reacting with glacierlike fluidity until the desired result is
achieved. Trees, structures, rock formations, and such are mostly
unaffected except for changes in elevation and relative topography.</p>
    <p>The spell cannot be used for tunneling and is generally too
slow to trap or bury creatures. Its primary use is for digging or
filling moats or for adjusting terrain contours before a battle.</p>
    <p>This spell has no effect on earth creatures.</p>
    <p><i>Material Component</i>: A mixture of soils (clay, loam, and
sand) in a small bag, and an iron blade.</p>
    <h6><a id="neutralize-poison">Neutralize Poison</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Clr 4, Drd 3, Pal
4, Rgr 3</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature or object of up
to 1 cu. ft./level touched</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>You detoxify any sort of venom in the creature or object
touched. A poisoned creature suffers no additional effects from the
poison, and any temporary effects are ended, but the spell does not
reverse instantaneous effects, such as hit point damage, temporary <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, or effects that
don&rsquo;t go away on their own.</p>
    <p>The creature is immune to any poison it is exposed to during
the duration of the spell. Unlike with delay poison, such effects
aren&rsquo;t postponed until after the duration &mdash;the creature
need not make
any saves against poison effects applied to it during the length of the
spell.</p>
    <p>This spell can instead neutralize the poison in a poisonous
creature or object for the duration of the spell, at the caster&rsquo;s
option.</p>
    <p><i>Arcane Material Component</i>: A bit of charcoal.</p>
    <h6><a id="nightmare">Nightmare</a></h6>
    <p className="initial"><i>Illusion (Phantasm) [Mind-Affecting, Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Target</b>: One living creature</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You send a hideous and unsettling phantasmal vision to a
specific creature that you name or otherwise specifically designate.</p>
    <p>The nightmare prevents restful sleep and causes 1d10 points of
damage. The nightmare leaves the subject <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a> and unable to regain
arcane spells for the next 24 hours.</p>
    <p>The difficulty of the save depends on how well you know the
subject and what sort of physical connection (if any) you have to that
creature.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "70%"
          }}>Knowledge</th>
          <th style={{
            "width": "30%"
          }}>Will Save Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>None<sup>1</sup></td>
          <td>+10</td>
        </tr>
        <tr>
          <td>Secondhand (you have heard of the subject)</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>Firsthand (you have met the subject)</td>
          <td>+0</td>
        </tr>
        <tr>
          <td className="last-row">Familiar (you know the subject well)</td>
          <td className="last-row">&ndash;5</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>You must have some sort of connection
to a creature you have no knowledge of.</i></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "70%"
          }}>Connection</th>
          <th style={{
            "width": "30%"
          }}>Will Save Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Likeness or picture</td>
          <td>&ndash;2</td>
        </tr>
        <tr>
          <td>Possession or garment</td>
          <td>&ndash;4</td>
        </tr>
        <tr className="odd-row">
          <td>Body part, lock of hair, bit of nail, etc.</td>
          <td>&ndash;10</td>
        </tr>
      </tbody>
    </table>
    <p>Dispel evil cast on the subject while you are casting the
spell dispels the nightmare and causes you to be <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 10 minutes
per caster level of the dispel evil.</p>
    <p>If the recipient is awake when the spell begins, you can
choose to cease casting (ending the spell) or to enter a trance until
the recipient goes to sleep, whereupon you become alert again and
complete the casting. If you are disturbed during the trance, you must
succeed on a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check as if you
were in the midst of casting a spell or the spell ends.</p>
    <p>If you choose to enter a trance, you are not aware of your
surroundings or the activities around you while in the trance.</p>
    <p>You are defenseless, both physically and mentally, while in
the trance. (You always fail any saving throw, for example.)</p>
    <p>Creatures who don&rsquo;t sleep (such as elves, but not
half-elves)
or dream are immune to this spell.</p>
    <h6><a id="nondetection">Nondetection</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Rgr 4, Sor/Wiz 3, Trickery
3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature or object touched</span> <span className="stat-block"><b>Duration</b>:
1 hour/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>The warded creature or object becomes difficult to detect by
divination spells such as clairaudience/clairvoyance, locate object,
and detect spells. Nondetection also prevents location by such magic
items as crystal balls. If a divination is attempted against the warded
creature or item, the caster of the divination must succeed on a caster
level check (1d20 + caster level) against a DC of 11 + the caster level
of the spellcaster who cast nondetection. If you cast nondetection on
yourself or on an item currently in your possession, the DC is 15 +
your caster level.</p>
    <p>If cast on a creature, nondetection wards the creature&rsquo;s
gear
as well as the creature itself.</p>
    <p><i>Material Component</i>: A pinch of diamond dust worth 50 gp.</p>
    <h6><a id="obscure-object">Obscure Object</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Clr 3, Sor/Wiz 2</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
One object touched of up
to 100 lb./level</span> <span className="stat-block"><b>Duration</b>: 8
hours (D)</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This spell hides an object from location by divination
(scrying) effects, such as the scrying spell or a crystal ball. Such an
attempt automatically fails (if the divination is targeted on the
object) or fails to perceive the object (if the divination is targeted
on a nearby location, object, or person).</p>
    <p><i>Arcane Material Component</i>: A piece of chameleon skin.</p>
    <h6><a id="obscuring-mist">Obscuring Mist</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Air 1, Clr 1, Drd 1,
Sor/Wiz 1, Water 1</span> <span className="stat-block"><b>Components</b>:
V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: 20 ft.</span> <span className="stat-block"><b>Effect</b>: Cloud spreads in 20-ft.
radius from you, 20 ft. high</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A misty vapor arises around you. It is stationary once
created. The vapor obscures all sight, including darkvision, beyond 5
feet. A creature 5 feet away has concealment (attacks have a 20% miss
chance). Creatures farther away have total concealment (50% miss
chance, and the attacker cannot use sight to locate the target).</p>
    <p>A moderate wind (11+ mph), such as from a gust of wind spell,
disperses the fog in 4 rounds. A strong wind (21+ mph) disperses the
fog in 1 round. A fireball, flame strike, or similar spell burns away
the fog in the explosive or fiery spell&rsquo;s area. A wall of fire
burns
away the fog in the area into which it deals damage.</p>
    <p>This spell does not function underwater.</p>
    <h6><a id="open-close">Open/Close</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: Object weighing
up to 30
lb. or portal that can be opened or closed</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>You can open or close (your choice) a door, chest, box,
window, bag, pouch, bottle, barrel, or other container. If anything
resists this activity (such as a bar on a door or a lock on a chest),
the spell fails. In addition, the spell can only open and close things
weighing 30 pounds or less. Thus, doors, chests, and similar objects
sized for enormous creatures may be beyond this spell&rsquo;s ability
to
affect.</p>
    <p><i>Focus</i>: A brass key.</p>
    <h6><a id="orders-wrath">Order&rsquo;s Wrath</a></h6>
    <p className="initial"><i>Evocation [Lawful]</i></p>
    <span className="stat-block"><b>Level</b>: Law 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Nonlawful
creatures within
a burst that fills a 30-ft. cube</span> <span className="stat-block"><b>Duration</b>:
Instantaneous (1
round); see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You channel lawful power to smite enemies. The power takes the
form of a three-dimensional grid of energy. Only chaotic and neutral
(not lawful) creatures are harmed by the spell.</p>
    <p>The spell deals 1d8 points of damage per two caster levels
(maximum 5d8) to chaotic creatures (or 1d6 points of damage per caster
level, maximum 10d6, to chaotic outsiders) and causes them to be <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a>
for 1 round. A successful Will save reduces the damage to half and
negates the daze effect.</p>
    <p>The spell deals only half damage to creatures who are neither
chaotic nor lawful, and they are not dazed. They can reduce the damage
in half again (down to one-quarter of the roll) with a successful Will
save.</p>
    <h6><a id="overland-flight">Overland Flight</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level</span>
    <p>This spell functions like a <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fly">fly</a> spell, except you can fly at a
speed of 40 feet (30 feet if wearing medium or heavy armor, or if
carrying a medium or heavy load) with average maneuverability. When
using this spell for long-distance movement, you can hustle without
taking nonlethal damage (a forced march still requires Constitution
checks). This means you can cover 64 miles in an eight-hour period of
flight (or 48 miles at a speed of 30 feet).</p>
    <h6><a id="owls-wisdom">Owl&rsquo;s Wisdom</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Drd 2, Pal 2, Rgr
2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S,
M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The transmuted creature becomes wiser. The spell grants a +4
enhancement bonus to Wisdom, adding the usual benefit to Wisdom-related
skills. Clerics, druids, paladins, and rangers (and other Wisdom-based
spellcasters) who receive owl&rsquo;s wisdom do not gain any additional
bonus
spells for the increased Wisdom, but the save DCs for their spells
increase.</p>
    <p><i>Arcane Material Component</i>: A few feathers, or a pinch
of droppings, from an owl.</p>
    <h6><a id="mass-owls-wisdom">Owl&rsquo;s Wisdom, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#owls-wisdom">Owl&rsquo;s Wisdom</a>, except that it
affects
multiple creatures.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      